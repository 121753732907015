import React from 'react';
import { Button } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const BorderMagicButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  height: '3rem', // Equivalent to h-12
  overflow: 'hidden',
  borderRadius: '9999px', // Equivalent to rounded-full
  padding: '1px',
  
  backgroundColor: '#181818',
  outline: 'none',
  '&:focus': {
    outline: 'none',
  },
  '& .background': {
    position: 'absolute',
    inset: '-1000%',
    background: 'conic-gradient(from 90deg at 50% 50%, #FF512F 0%, #DD2476 50%, #EE5D9E 100%)',
    animation: `${spin} 2s linear infinite`,
  },
  '& .content': {
    display: 'inline-flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '9999px',
    backgroundColor: '#080f25', // Equivalent to bg-slate-950
    padding: '0.25rem 1.75rem', // Equivalent to px-3 py-1
    fontSize: '0.875rem', // Equivalent to text-sm
    fontWeight: '500', // Equivalent to font-medium
    color: '#fff', // Equivalent to text-white
    backdropFilter: 'blur(10px)', // Equivalent to backdrop-blur-3xl
  },
}));

const MagicButton = ({children}) => {
  return (
      <BorderMagicButton type='submit' >
        <span className="background" />
        <span className="content">{children}</span>
      </BorderMagicButton>
  );
};

export default MagicButton;
